@font-face {
  font-family: libreFranklin;
  src: url(libre_franklin.ttf);
}
html {
  background-color: #121213;
}

.App {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  background-color: #121213;
  color: #f8f8f8;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

.Greeting {
  display: flex;
  align-items: center;
  margin: 0.5lh;
}

.Profile {
  width: 4lh;
  height: 4lh;
  size: 200;
  border-radius: 2lh;
  transition: 0.25s all;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

.Unselectable {
  cursor: context-menu;
}

.AppButton {
  display: inline-flex;
  background-color: #f8f8f8;
  border: 0;
  border-radius: 2lh;
  margin-top: 1lh;
  margin-bottom: 2lh;
  padding: 0.35lh;

  color: #f8f8f8;
  opacity: 100%;
  font-size: x-large;
  font-family: Arial, Helvetica, sans-serif;
  font-weight: 550;
  transition: 0.1s;
  
}

.AppButton:active {
  padding: 0.25lh;
}

.AppButtonIMG {
  color: #f8f8f8;
  opacity: 100%;
  width: 1.5lh;
  height: 1.5lh;
  user-select: none;
  cursor: pointer;
}

.Credits {
  font-size: xx-small;
  opacity: 65%;
}

.Credits a {
  color: #61dafb;
}

.UserContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  border-width: 0.1lh;
  border-radius: 0.65lh;
  border-style: solid;
  border-color: #f8f8f8;
  padding: 1lh;
  
  margin: 0.5lh;
}

.UserName {
  margin-top: 0.25lh;
  font-size: xx-large;
  font-weight: 600;
}

.PointText {
  font-weight: bold;
  font-size: medium;
}

.UserPoints {
  margin-top: 0lh;
  font-size: xx-large;
  font-weight: 1000;
}

.StatIcon {
  width: 2.75lh;
}

.UserStats {
  display: flex;
    margin-top: 2lh;
    width: 75%;
    flex-wrap: wrap;
    justify-content: center;
}

.numStat {
  text-align: center;
  margin-bottom: 1lh;
  padding: 0.25lh;
  font-size: 1lh;
  border: 0;
  border-radius: 0.5lh;
}

.App-Title {
  font-size: x-large;
  font-family: libreFranklin;
}

.gameDate {
  margin: 0.4lh;
}

.WordleDate {
  display: block;
  margin-bottom: 1lh;
  padding: 0.25lh;
  font-size: 1lh;
  border: 0;
  border-radius: 0.5lh;
}

.WordleAnswer {
  border: #444b57;
  border-radius: 1lh;
  border-style: solid;
  padding-top: 0.75lh;
  padding-bottom: 0.75lh;
  margin-bottom: 1.5lh;
  width: 16.25lh;

  user-select: none;
  cursor: pointer;
}

.test {
  margin-top: -2.25lh;
  background-color: #121213;
  border-radius: 0.75lh;;
  color: #f8f8f8;
  font-weight: 500;
  padding-left: 0.8lh;
  padding-right: 0.8lh;
  padding-bottom: 0.05lh;
  transition: 0s;

  user-select: none;
  cursor: pointer;
}

div.WordleLetter {
  display: inline-block;
  background-color: #538d4e;
  margin-left: 0.15lh;
  margin-right: 0.15lh;
  width: 1.25lh;
  height: 1.25lh;

  color: #f8f8f8;
  font-size: xx-large;
  font-family: libreFranklin;
  font-weight: 700;
}

p.WordleLetter {
 margin-top: 0.125lh; 
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
